import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from "gatsby"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title}></SEO>
      <div className="container mx-auto px-5 py-2">
        <h1 className="text-lg">
          {frontmatter.title}&nbsp;
        <span className="text-xs text-gray-500 uppercase">{frontmatter.artist} </span>
        </h1>
        <div
          className="text-xs py-5"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path:{ eq: $path }}) {
      html
      frontmatter {
        artist
        title
      }
    }
  }
`
